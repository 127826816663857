.searchWrap{
  :global{
    .ant-input-group .ant-input{
      width: 272px;
      font-size: 15px;
      line-height: 25px;
    }
    .ant-input-search-button{
      width: 77px;
      background: #057564;
      &:hover{
        background: #055D4F;
      }
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;