.list{
    margin-top: 30px;
    background:#fff;
    display: flex;
    justify-content: space-between;
    .item{
        font-size: 16px;
        cursor: pointer;

    }
    .swiper{
      width: 803px;
      height: 362px;
      margin-right: 30px;
      img{
        width: 100%;
        height: 362px;
        margin-left:-1px;
      }
    }
    .rightBox{
      width: 348px;
      text-align: center;
      padding: 24px 40px;
      background: #F5F3F3;
      .rightTitle{
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 25px;
      }
      .submitBtn{
        width: 100%;
        font-size: 20px;
        height: 47px;
        color:#fff;
        background: #057564;
        border-radius: 5px;
        &:hover{
          background: #055D4F;
        }
      }
    }

  :global{
    .ant-form-item-label > label{
      font-size: 16px;
      height: 40px;
    }
    .ant-input-suffix{
      font-size: 14px;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;