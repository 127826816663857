.list{
  .item{
    display: flex;
    padding: 5px 30px;
    font-size: 16px;
    line-height: 30px;
    color:#333;
    cursor: pointer;
    border:1px solid rgba(238, 238, 238, 1);
    .title,.type,.area,.regin,.biddingType{
      flex:1;
      overflow: hidden;
    }
    .title{
      flex:2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
    .time{
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
    &.tableTitle{
      font-weight: bold;
      color: #000;
      line-height: 36px;
      &:hover{
        color: #333;
        cursor: default;
      }
    }
    &:nth-child(2n){
      background: #efefef;
    }
    &:hover{
      color: #1FAE94;
    }
  }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;