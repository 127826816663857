.box{
    background: #F6F4F5;
    .banner{
        display: block;
        width: 100%;
    }
  .content{
    position: relative;
    padding: 36px 67px;
    margin-top: 35px;
    background: #fff;
    .form{
      padding: 50px 100px ;
    }
  }
  .ext{
    position: absolute;
    bottom: 200px;
    right: 400px;
    a{
      color: #4f9dfb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .trigger {
    display: flex;
    margin-top: 20px;
    Button {
      width: 160px;
      height: 42px;
      font-size: 18px;
      border: 0;
      margin-right: 30px;

      &:last-child{
        margin-right: 0;
      }
    }
    .prePage{
      width: 160px;
      height: 42px;
      font-size: 18px;
      color: #1DBC86;
      background: #fff;
      border: 1px solid #1DBC86;
      margin-right: 30px;
      &:hover {
        color: #067562;
        background: #fff;
        border: 1px solid #067562;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .saveBtn{
      background: #EFC740;
      color: #fff;
      border: 0;
      margin: 0 20px;
      &:hover{
        color: #fff;
        border-color: #EFC740;
      }
    }
  }
  .codeBtn{

    background: #1DBC86;
    border:0;
    &:hover{
      background: #067562;
    }
  }


  .pactionText{
    margin-bottom: 5px;
  }
  .status_img{
      width: 74px;
    height: 74px;
  }
  .result{
    text-align: center;
    .title{
      font-size: 20px;
      margin-top: 18px;
    }
    .subtitle{
      font-size: 18px;
      color: #B8B7B7;
      margin-top: 10px;
    }
    .await{
      color: #1CBB85;
    }
    .success{
      color: #FF6600;
    }
    .reject{
      color: #FF0000;
    }
    .remark{
      margin-top: 20px;
      color: #333;
    }
  }

  :global {
    .ant-tabs-nav,.ant-tabs-bar {
      display: none !important;
    }
    .ant-form-item textarea.ant-input{
      min-height: 100px;
    }
    .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
      font-size: 12px;
    }
    .ant-input[disabled]{
      color: #999;
      background: #fff;
    }

  }
}

@primary-color: #0f8605;@border-radius-base: 2px;