.body{
  flex: 1;
  background: #fff;
  padding: 0 22px;
  min-height: calc(100vh - 370px);

  .content {
    //text-align: center;
    padding: 28px 88px;
    margin-bottom: 36px;
  }
}


@primary-color: #0f8605;@border-radius-base: 2px;