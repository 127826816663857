.yc-radio {
  display: flex;
  margin-bottom: 10px;
  font-size: 15px;
  user-select: none;

  .label {
    font-size: 15px;
    font-weight: bold;
    line-height: 27px;

  }

  .list {
    padding-left: 10px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    line-height: 27px;

    .item {
      font-style: normal;
      margin-right: 8px;
      margin-bottom: 5px;
      cursor: pointer;
      padding: 0 10px;
      border-radius: 3px;

      &.active {
        background: #1FAE94;
        color: #fff;
        &:hover{
          color: #fff;
        }
      }
      &:hover{
        color: #1FAE94;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .toggle {
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    color: #1BAE96;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;