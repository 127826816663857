.wrap{
    display: flex;
    align-items: center;
    justify-content:space-between;
    height: 100px;
    .logo{
        cursor: pointer;
      img{
        width: 420px;
      }
    }
    .searchWrap{
    }
    .ext{
        cursor: pointer;
        //border-radius: 4px;
        //border:1px solid #efc107;
        font-size: 14px;
        border:1px solid #ff9c45;
        color:#ff9c45;
        background: #fff;
        padding: 0 15px;
        line-height: 30px;
        font-weight: normal;
        .iconbianji{
            margin-right: 8px;
        }
    }
    .ext:hover{
        background: #ff9c45;
        color: #fff;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;