
.title {
  position: relative;
  color: #333;
  font-size: 16px;
  line-height: 53px;
  border-bottom: 1px solid #F5F5F5;
  padding: 0 20px;
  &:before {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 28px;
    height: 2px;
    width: 46px;
    border-radius: 1px;
    background: #077462;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;