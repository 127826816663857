.industry{

    button{
      width: 100%;
      height: 36px;
      font-size: 14px;
      color: #666;
      text-align: center !important;
      border-color: #ddd;
      border-radius: 5px;
      padding: 0;
    }
    button.check {
      color: #1CBB85;
      border-color: #1CBB85;

    }
    :global{
      .ant-btn:hover{
        color: #067562;
        border-color: #067562;
      }
    }

}
@primary-color: #0f8605;@border-radius-base: 2px;