.steps{
  :global{
    .ant-steps-item-process .ant-steps-item-icon{
        background: #1DBC86;
        border-color: #1DBC86;
    }
    .ant-steps-item-finish .ant-steps-item-icon{
      background-color: #fff;
      border-color: #1DBC86;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
      background-color: #1DBC86;

    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
      color: #1DBC86;
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;