.footer{
  background: #F6F4F5;
  .ext{
    background: #464646;
    color: #fff;
    .content{
      display: flex;
      padding: 15px 0 ;
      .extRight{
        width: 630px;
        height: 72px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 40px;
        border-right: 1px solid #fff;
        margin: 15px 0;
        .title{
          font-size:16px;
          line-height: 14px;
          color: #fff;
          padding-bottom: 20px;
        }
        .row{
          a{
            display: inline-block;
            line-height: 14px;
            font-size: 14px;
            color: #eee;
            padding-right: 10px;
            border-right: 1px solid #eee;
            margin-right: 10px;
            &:hover{
              color: #1A9825;
              text-decoration: underline;
            }
            &:last-child{
              border: 0;
            }
          }
        }
      }
      .extLeft{
        width: 470px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 53px;
        .contact{
          font-size:14px;
          line-height: 26px;
        }
        .QRcode{
          img{
            width: 80px;
            height: 80px;
            display: block;
          }
          .text{
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }


  }

  .footerBottom{

        background: #000;
        height: 124px;
        padding-top: 28px;
        color:#bbb;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 26px;
      a{
        display:inline-block;
        text-decoration:none;
        height:20px;
        line-height:20px;
        color: #bbb;
        img{
          display: inline-block;
          margin-right: 5px;
        }
        span{
          vertical-align: middle;
        }
      }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;