.list{
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 216px;
        color:#333;
        cursor: pointer;
        text-align: center;
        border: 1px solid #DDDDDD;
        margin-top: 20px;
        border-radius: 3px;
        margin-right: 25px;
        &:hover{
          box-shadow: 0 0 5px 5px #eee;
        }
        &:nth-of-type(5n+5){
          margin-right: 0;
        }
        .title{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 73px;
          font-size: 16px;
          padding: 8px 16px;
          background: #FCF9FC;
          vertical-align: middle;
        }
        .money{
          height: 56px;
          line-height: 56px;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          margin-top: 5px;
          span{
            vertical-align: top;
            font-size: 24px;
          }
        }
        .detail{
          line-height: 14px;
          font-size: 14px;
          margin: 10px 0;
          box-sizing: border-box;
          padding: 0 10px;
          span {
            width: 50%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child{
              text-align: right;
              padding-right: 20px;
              border-right: 1px solid #DDDDDD;
            }
            &:last-child{
              padding-left: 20px;
              text-align: left;
              //border-left: 1px solid #DDDDDD;
            }
          }


        }

        .btn{
          line-height: 41px;
          background: #F78F39;
          color: #fff;
          font-size: 16px;
          margin: 30px 40px;
          &:hover{
            color: #fff;
            border-color: #EFC740;
            background: #EFC740;
          }
        }
    }
  :global{

  }
}
@primary-color: #0f8605;@border-radius-base: 2px;