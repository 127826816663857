.card {
  border:1px solid rgba(246, 246, 246, 1);
  border-radius: 5px;
  .title{
    background: #F6F5F8;
    line-height: 42px;
    font-size: 16px;
    padding: 0 13px;
    color: #056152;
  }
  .list{
    padding: 0 15px 15px 15px ;
    .item {
      font-size: 13px;
      cursor: pointer;
      display: flex;
      padding-top: 10px;
      &:hover{
        color: #1FAE94;
      }
      .index{
        width: 19px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        text-align: center;
        color: #bbb;
        background: #ECFAF8;
        margin-right: 15px;

      }
      &:nth-child(-n + 3) .index{
        color: #fff;
        background: #20AD94;
      }
      .name {
        position: relative;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;

      }


    }

  }

}
@primary-color: #0f8605;@border-radius-base: 2px;