.userinfo{
    text-align: center;
    background:#fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    h3{
        color:#fff;
        font-size:18px;
        line-height: 51px;
        background: #017462;
        margin-bottom: 20px;
    }
    .phone,.company{
        font-size: 14px;
        color:#666;
        margin-bottom: 5px;
    }
    .phone{
        margin-top: 5px;
        margin-bottom: 0;
    }
    .avatar{
      position: relative;
      width: 56px;
      margin: auto;
      cursor: pointer;
    }

    .avatar:hover{
      .updateAvatar{
        display: block;
      }
    }
    .updateAvatar{
       position: absolute;
      display: none;
      top: 50%;
      width: 100%;
      line-height: 18px;
      color: #ddd;
      background: rgba(0,0,0,.3);;
      transform: translate(0,-50%);

    }
  .authenticate{
    display: inline-block;
    width: 100%;
    color: #017462;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    margin-bottom: 5px;
    img{
      display: inline-block;
      vertical-align: middle;
    }
    span{
      vertical-align: middle;
      margin-left: 4px;
    }
  }
    .btn{
        width: 120px;
        line-height: 32px;
        cursor: pointer;
        border-radius: 20px;
        color:#fff;
        background:#fbc330;
        align-self:center;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;