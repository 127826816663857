.statusWarp{
  .typeBox{
    display: flex;
    &>div{
      padding: 0 10px;
      line-height: 26px;
      font-size: 14px;
      color: #666;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
    }
    .active{
      background: #017462;
      font-weight: bold;
      color: #fff;
    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;