.list{
    .item{
        display: flex;
        padding: 5px 0;
        font-size: 16px;
        color:#555;
        cursor: pointer;
        .title,.type,.area,.regin,.biddingType{
            width: 115px;
            overflow: hidden;
            text-align: center;
        }
        .title{
            flex:2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
      .regin{
        width: 160px;
      }
        .time{
          text-align: center;
            width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &.tableTitle{
          color: #000;
          line-height: 32px;
          font-weight: bold;

          &:hover{
            color: #333;
            cursor: default;
          }
        }
        &:nth-child(2n){
            background: #F8F8F8;
        }
      &:hover{
        color: #1FAE94;
      }
    }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;