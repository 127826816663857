.body{
    display: flex;
    justify-content: space-between;
    margin:20px 0;
    min-height: calc(100vh - 270px);
    .sideBar{
        width: 280px;
        margin-right: 20px;
        background: #fff;
    }
    .mainBody{
        width: 870px;
        overflow: hidden;
    }
  .typeBox{
    display: flex;
    &>div{
      width: 80px;
      line-height: 26px;
      font-size: 14px;
      color: #666;
      text-align: center;
      cursor: pointer;
    }
    .active{
      background: #017462;
      font-weight: bold;
      color: #fff;
    }
  }

  .row{
    display: flex;
    border-top: 1px solid #eee;
    font-size: 16px;
    &:first-child{
      border:0;
    }
    .name{
      width: 400px;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      &:hover{
        color: #077462;
      }
    }
    .time{
      width: 185px;
      line-height: 50px;
      text-align: center;
    }
    .status{
      color: #5699E5;
      line-height: 50px;
      text-align: center;
      flex: 1;
    }
    .operation{
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
       .btn{
          width: 83px;
          line-height: 27px;
          font-size: 14px;
          color: #999;
         text-align: center;
         cursor: pointer;
         border-radius: 5px;
          &:hover{
            background: #14AF97;
            color: #fff;
          }
       }
    }
  }
  .header{
    margin: 0 37px 0 0;
    border-bottom: 1px solid #eee;
    .name{
      font-weight: normal;
      text-align: center;
      cursor: default;
      &:hover{
        color: inherit;
      }
    }
    .status{
      color: inherit;
    }
  }
  .list{
    padding: 0 20px 0 0;
    max-height: 600px;
    overflow-y: scroll;
    //position: relative;
  }

  :global{
    .ant-form-item{
      margin-bottom: 15px;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;