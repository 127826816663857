.list{
    padding: 9px 0;
    .item{
        font-size: 16px;
        display: flex;
        line-height: 36px;
        color:#333;
        cursor: default;
        // position:relative;
        /* &:before{
            position:absolute;
            content: '';
            left: 0;
            top:50%;
            margin-top: -3px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background:#999;
        } */
        .title{
            flex:1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            img{
              display: inline-block;
              width: 10px;
              height: 10px;
              margin-right: 3px;
              margin-bottom: 1px;
            }
          //&:hover{
          //  color: #FF9C45;
          //}
        }
        .time{
            color:#999;
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;