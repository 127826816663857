.card{
    flex:1;
    display: flex;
    flex-direction: column;
    border-top: 3px solid #017462;
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
        background: #F0F3F9;
        color: #017462;
        line-height: 48px;
        padding-left: 20px;
        font-size: 18px;
      .extra{
      }
    }

    .cardBody{
        padding: 20px;
        background:#fff;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;