.main-nav{
  background: #067562;
  .navList{
    display: flex;
    line-height: 46px;
      .nav{
          padding: 0 30px;
          font-size: 18px;
          color:#fff;
        white-space: nowrap;
        &.selected,&:hover{
              background: #055D4F;
          }
          >div{
              text-align: center;
          }
      }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;