.cardbox{
    background: #fff;
    border-radius: 2px;
    height: auto;
    .title{
        padding: 3px 0 3px 14px;
        border-bottom: 1px solid #F3F0F3;
        font-size: 20px;
        font-weight: bold;
        color:#000;
        display: flex;
        align-items: flex-end;
        position: relative;
        margin-top: 20px;
        &:before{
            position:absolute;
            top:50%;
            left: 0;
            content: '';
            width: 4px;
            height: 18px;
            background: #1E6851;
            margin-top: -8px;
        }
        .ext{
            margin-left: 30px;
            i{
                margin-right: 20px;
                font-style: normal;
                color:#999;
                position:relative;
                cursor: pointer;
                &:hover{
                    color:#FF9C45;
                }
                &:after{
                    position:absolute;
                    content: '';
                    width: 1px;
                    height: 16px;
                    top:50%;
                    margin-top: -6px;
                    right: -10px;
                    background:#ddd;
                }
                &:last-child{
                    margin-right: 0;
                    &:after{
                        visibility: hidden;
                    }
                }
            }
        }
        .blank{
            flex:1;
            overflow: hidden;
        }
        .moreBtn{
            cursor: pointer;
            color: #B8B7B7;
            font-weight: normal;
            font-size: 14px;
          &:hover{
            color: #057564;
          }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;