
.navList {
  flex: none;
  width: 210px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(3, 0, 1, 0.07);
  .nav {
    position: relative;
    //border-bottom: 1px solid #E5E5E5;
    background: #fff;
    color: #333333;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #F5F5F3;
    &:last-child{
      border-bottom: 0;
    }
  }

  .selected{
    color: #fff;
    background: #077462;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;